<template>
	<r-flex class="login-W" align="center">
		<r-flex flx="3" align="center" class="login-C">
			<r-flex align="center" class="r-mb-162">
				<img src="@/assets/img/logo-tusdata.png" />
			</r-flex>
			<r-flex align="center" class="r-mb-32" v-if="message !== ''">
				<div class="alert-txt-C">
					{{ message }}
				</div>
			</r-flex>
			<r-flex align="center" class="r-mb-16">
				<r-input v-model="user" label="Kullanıcı Adı" class="r-mb-16" />
				<r-input v-model="pass" label="Şifre" type="password" />
			</r-flex>
			<r-flex align="center" class="r-mb-16">
				<h4 class="r-mb-16" style="width: 100%; text-align: center">
					Giriş yapmak istediğiniz bölümü seçin
				</h4>
				<r-flex
					align="center"
					flx="4"
					v-for="(type, i) in typeList"
					:key="i"
					@click="changeType(type)"
					:class="[
						'login-select-type-img',
						store.state.usertype === i ? 'selected' : '',
					]"
				>
					<img :src="type.img" />
				</r-flex>
			</r-flex>
			<r-flex>
				<r-btn
					bgcolor="#15880d"
					@click="login"
					style="width: 100%"
					:disabled="canClick"
				>
					Giriş Yap
				</r-btn>
			</r-flex>
		</r-flex>
	</r-flex>
</template>
<script>
	import { onBeforeMount, ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import * as Cookies from 'js-cookie'
	import router from '@/router/'
	import CryptoJS from 'crypto-js'
	export default {
		setup() {
			const store = useStore()
			const user = ref('')
			const pass = ref('')
			const message = ref('')

			const canClick = computed(() => {
				if (
					user.value !== '' &&
					pass.value !== '' &&
					store.state.usertype !== null
				)
					return false
				else return true
			})

			const typeList = ref([
				{
					type: 0,
					img: require('@/assets/img/logo-tusdata-s.png'),
				}
			])

			const login = async () => {
				let login = await store.dispatch('postDatas', {
					api: 'user/login',
					pos: 'login',
					data: {
						Email: user.value,
						Password: CryptoJS.MD5(pass.value).toString(),
					},
				})
				goLogin(login)
			}
			const goLogin = l => {
				if (l.data) {
					let d = {
						t: l.data.token,
						n: l.data.name,
						uid: l.data.id,
						aut: l.data.auths,
						r: l.data.roleId,
						cid: l.data.categoryId,
					}
					let t = {
						ut: store.state.usertype,
					}
					Cookies.set('tdutype', JSON.stringify(t))
					store.state.loggedin = true
					store.state.username = l.data.name
					store.state.userid = l.data.id
					store.state.usercatid = l.data.categoryId
					store.state.userauth = l.data.auths
					if (store.state.usertype === 0)
						Cookies.set('tdataref', JSON.stringify(d))
					if (store.state.usertype === 1)
						Cookies.set('ddataref', JSON.stringify(d))
					if (store.state.usertype === 2)
						Cookies.set('yddataref', JSON.stringify(d))
					router.push('/referanslar/liste/')
				} else {
					message.value = l.msgDetail.msg
				}
			}
			const changeType = d => {
				store.state.usertype = d.type
			}
			onBeforeMount(async () => {
				const params = window.location.search
				if (params) {
					const urlParams = new URLSearchParams(params)
					const type = urlParams.get('type')
					const u = urlParams.get('u')
					const h = urlParams.get('h')
					store.state.usertype = parseInt(type)
					if (type && u && h) {
						let login = await store.dispatch('postDatas', {
							api: 'user/checkuser',
							pos: 'login',
							data: {
								Type: parseInt(type),
								UserId: parseInt(u),
								HashKey: h,
							},
						})
						goLogin(login)
					}
				}
			})
			return {
				user,
				pass,
				login,
				message,
				typeList,
				changeType,
				store,
				canClick,
			}
		},
	}
</script>
<style>
	.login-W {
		width: 100%;
		height: 100%;
	}
	.login-C {
		border-radius: 9px;
		padding: 24px;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		flex: 0 0 auto;
		background-color: #fff;
	}
	.alert-txt-C {
		background-color: rgb(243, 170, 170);
		color: rgb(148, 21, 21);
		width: 100%;
		padding: 16px;
		text-align: center;
		border-radius: 9px;
	}
	.login-select-type-img {
		border: 1px solid #ccc;
		padding: 32px 8px;
		margin: 2%;
		width: 29%;
		cursor: pointer;
		border-radius: 4px;
		flex-basis: 29%;
	}
	.login-select-type-img.selected {
		background-color: #d6f7fb;
	}
	.login-select-type-img:hover {
		background-color: rgb(218, 218, 218);
	}
	.login-select-type-img img {
		width: 100%;
		max-width: 120px;
	}
	@media only screen and (max-width: 1240px) {
		.login-C {
			width: 50%;
		}
	}
	@media only screen and (max-width: 640px) {
		.login-C {
			width: 80%;
		}
	}
</style>
